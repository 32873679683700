import React, { FC, useEffect, useState } from 'react'
import { ThemeEnum } from '../../types'
import styles from './ThemeSwitcher.module.scss'
import ThemeIcon from '../../public/icons/theme-icon.svg'
import cn from 'classnames'
import AnimateHeight from 'react-animate-height'

const themes = [
  {
    name: 'Как на устройстве',
    code: ThemeEnum.system,
  },
  {
    name: 'Светлая',
    code: ThemeEnum.light,
  },
  {
    name: 'Темная',
    code: ThemeEnum.dark,
  },
]

const ThemeSwitcher: FC<{
  defaultTheme: ThemeEnum
  onChange: (value: ThemeEnum) => void
  className?: string
}> = ({ defaultTheme, onChange, className }) => {
  const [open, setOpen] = useState(false)
  const [theme, setTheme] = useState<ThemeEnum>(defaultTheme)

  useEffect(() => {
    setTheme(defaultTheme)
  }, [defaultTheme])

  return (
    <div className={cn(styles.container, className)}>
      <button
        className={cn('ls-01', styles.openButton)}
        type="button"
        onClick={() => setOpen((prev) => !prev)}
      >
        Изменить тему
        <ThemeIcon />
      </button>
      <div className={styles.content}>
        <AnimateHeight height={open ? 'auto' : 0} duration={200}>
          <ul className={styles.list}>
            {themes.map(({ name, code }) => (
              <li key={name}>
                <button
                  className={cn(
                    'ls-03',
                    styles.item,
                    code === theme && styles['item--active']
                  )}
                  onClick={() => {
                    setOpen(false)
                    onChange(code)
                  }}
                >
                  {name}
                </button>
              </li>
            ))}
          </ul>
        </AnimateHeight>
      </div>
    </div>
  )
}

export default ThemeSwitcher
